import './bootstrap'

import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {createApp, defineAsyncComponent} from 'vue';
import {createPinia} from 'pinia'
import {createI18n} from 'vue-i18n'
import {useAuthStore} from "./store/auth";

const BulkSMS = defineAsyncComponent(() => import('./components/BulkSms/index.vue'));
const MpesaAccountIndex = defineAsyncComponent(() => import('./components/BulkSms/index.vue'));
const ReferralCode = defineAsyncComponent(() => import('./components/v4/market/compliance/MemberCompliance/ReferralCodeCompliance.vue'));
const AssignOrder = defineAsyncComponent(() => import('./components/Orders/SupplyAgent/AssignOrder.vue'));

const PriceIndex = defineAsyncComponent(() => import('./components/PriceUpdates/Index.vue'));
const WalletIndex = defineAsyncComponent(() => import('./components/wallet/index.vue'));
const WalletTransfer = defineAsyncComponent(() => import('./components/wallet/transfer/Index.vue'));
const ProductAddition = defineAsyncComponent(() => import('./components/v4/products/create.vue'));
const PaymentIndex = defineAsyncComponent(() => import('./components/wallet/payments/Index.vue'));
const RequestPayment = defineAsyncComponent(() => import('./components/wallet/requestpayment/Index.vue'));
const CreateFees = defineAsyncComponent(() => import('./components/rent_and_fees/Create.vue'));
const CreateCharge = defineAsyncComponent(() => import('./components/rent_and_fees/CreateCharge.vue'));
const DeleteChargeFee = defineAsyncComponent(() => import('./components/rent_and_fees/DeleteChargeFee.vue'));
const MarketsIndex = defineAsyncComponent(() => import('./components/User/Markets/MarketsIndex.vue'));
const ReceivePayment = defineAsyncComponent(() => import('./components/Payments/ReceivePayment.vue'));
const PayFeesIndex = defineAsyncComponent(() => import('./components/v4/market/fee/PayFees/Index.vue'));
const PayFeesShow = defineAsyncComponent(() => import('./components/v4/market/fee/PayFees/Show.vue'));
const VerificationIcons = defineAsyncComponent(() => import('./components/v4/Verification/UserIcons.vue'));
const MarketMembers = defineAsyncComponent(() => import('./components/v4/market/members/Index.vue'));
const RBACIndex = defineAsyncComponent(() => import('./components/v4/admin/rbac/RBACIndex.vue'));
const AddUser = defineAsyncComponent(() => import('./components/v4/admin/rbac/AddUser.vue'));
const ComplianceIndex = defineAsyncComponent(() => import('./components/v4/compliance/ComplianceIndex.vue'));
const MarketComplianceFinance = defineAsyncComponent(() => import('./components/v4/finance/compliance/MarketComplianceFinance.vue'));
const FinanceUserCompliance = defineAsyncComponent(() => import('./components/v4/compliance/FinanceUserCompliance.vue'));
const CameraCapture = defineAsyncComponent(() => import('./components/userpicture/CameraCapture.vue'));
const PayForOrder = defineAsyncComponent(() => import('./components/wallet/orders/PayForOrder.vue'));
const UpcomingPayments = defineAsyncComponent(() => import('./components/Landing/UpcomingPayments.vue'));
const AcceptOrder = defineAsyncComponent(() => import('./components/AcceptOrder.vue'));
const MarketUpcomingPayments = defineAsyncComponent(() => import('./components/v4/market/MarketUpcomingPayments.vue'));
const MemberVerification = defineAsyncComponent(() => import('./components/v4/market/compliance/MemberCompliance/Index.vue'));
const MarketVerification = defineAsyncComponent(() => import('./components/v4/market/compliance/MarketCompliance/Index.vue'));
const PersonalInformationEditing = defineAsyncComponent(() => import('./components/v4/profile/PersonalInformationEditing.vue'));
const UpdatePassword = defineAsyncComponent(() => import('./components/v4/profile/UpdatePassword.vue'));
const UpdateAccountInformation = defineAsyncComponent(() => import('./components/v4/profile/UpdateAccountInformation.vue'));
const MarketProfile = defineAsyncComponent(() => import('./components/v4/profile/MarketProfile/Index.vue'));
const LoginPage = defineAsyncComponent(() => import('./components/v4/signin/LoginPage.vue'));
const MarketChanger = defineAsyncComponent(() => import('./components/MarketChanger.vue'));
const MarketCreation = defineAsyncComponent(() => import('./components/Admin/Markets/MarketCreation.vue'));
const FinanceSettings = defineAsyncComponent(() => import('./components/v4/admin/finance/settings/Index.vue'));
const MemberManagement = defineAsyncComponent(() => import('./components/v4/market/members/MemberManagement.vue'));
const ProfilePage = defineAsyncComponent(() => import('./components/User/Profile/ProfilePage.vue'));
const UserAvatar = defineAsyncComponent(() => import('./components/v4/profile/UserAvatar.vue'));
const UserNotifications = defineAsyncComponent(() => import('./components/v4/profile/UserNotifications.vue'));
const SummaryCards = defineAsyncComponent(() => import('./components/v4/admin/finance/loan_book/SummaryCards.vue'));
const CreditQueue = defineAsyncComponent(() => import('./components/v4/admin/finance/creditqueue/CreditQueue.vue'));
const MemberCreditQueue = defineAsyncComponent(() => import('./components/v4/market/creditqueue/MemberCreditQueue.vue'));
const Onboarding = defineAsyncComponent(() => import('./components/Admin/Markets/OnboardedMarkets/Index.vue'));
const BankAccount = defineAsyncComponent(() => import('./components/v4/admin/finance/bank_account/BankAccount.vue'));
const AddBankAccount = defineAsyncComponent(() => import('./components/v4/admin/finance/bank_account/AddBankAccount.vue'));
const UpdateBankAccountInfo = defineAsyncComponent(() => import('./components/User/Profile/UpdateBankAccountInfo.vue'));
const AddUserBankAccount = defineAsyncComponent(() => import('./components/v4/user/AddBankAccount.vue'));
const BankTransaction = defineAsyncComponent(() => import('./components/v4/admin/finance/bank_account/BankTransaction.vue'));
const CreateMarketMember = defineAsyncComponent(() => import('./components/Admin/Markets/CreateMarketMember.vue'));
const WithdrawalRequest = defineAsyncComponent(() => import('./components/v4/admin/finance/withdrawal_request/WithdrawalRequest.vue'));
const FeeAndCharge = defineAsyncComponent(() => import('./components/v4/market/fee/FeeAndCharge.vue'));
const FeeDetail = defineAsyncComponent(() => import('./components/rent_and_fees/FeeDetail.vue'));
const ChargeDetail = defineAsyncComponent(() => import('./components/rent_and_fees/ChargeDetail.vue'));
const FeeChargeCashout = defineAsyncComponent(() => import('./components/rent_and_fees/FeeChargeCashout.vue'));
const NewOrder = defineAsyncComponent(() => import('./components/v4/admin/finance/orders/NewOrder.vue'));
const UserAdministration = defineAsyncComponent(() => import('./components/v4/user/UserAdministration.vue'));
const OrderPayouts = defineAsyncComponent(() => import('./components/v4/admin/finance/payouts/Index.vue'));
const Transaction = defineAsyncComponent(() => import('./components/v4/market/members/Transaction.vue'));
const Subscription = defineAsyncComponent(() => import('./components/v4/admin/finance/subscriptions/Subscription.vue'));
const StaffAccess = defineAsyncComponent(() => import('./components/v4/admin/staff/marketaccess/index.vue'));
const Insurance = defineAsyncComponent(() => import('./components/Admin/Finance/insurance/index.vue'));
const MarketComplianceIndex = defineAsyncComponent(() => import('./components/v4/admin/finance/verifications/markets/Index.vue'));
const MarketComplianceShow = defineAsyncComponent(() => import('./components/v4/admin/finance/verifications/markets/Show.vue'));
const Product = defineAsyncComponent(() => import('./components/v4/products/Product.vue'));
const  ReportIncidence= defineAsyncComponent(() => import('./components/Payments/ReportIncidence.vue'));
const transactionstatus = defineAsyncComponent(() => import('./components/Admin/Finance/mpesa/payouts/transactionstatus.vue'));
const Earning = defineAsyncComponent(() => import('./components/v4/market/members/Earning.vue'));
const PosApplication = defineAsyncComponent(() => import('./components/v4/admin/finance/pos_application/PosApplication.vue'));
const LinkMpesaPayments = defineAsyncComponent(() => import('./components/Admin/Finance/mpesa/LinkMpesaPayments.vue'));
const FinanceOrder = defineAsyncComponent(() => import('./components/v4/admin/finance/orders/FinanceOrder.vue'));
const AdminNotifications = defineAsyncComponent(() => import('./components/Notifications/AdminNotifications.vue'));
const  WholesalerVerificationDocuments= defineAsyncComponent(() => import('./components/v4/market/compliance/MemberCompliance/Verifications/wholesaler/WholesalerVerificationDocuments.vue'));
const IdVerification = defineAsyncComponent(() => import('./components/v4/admin/finance/verifications/id/IdVerification.vue'));
const AddIdVerification = defineAsyncComponent(() => import('./components/v4/admin/finance/verifications/id/AddIdVerification.vue'));

const pinia = createPinia()
const app = createApp({});

// app.use(Flutterwave, { publicKey: import.meta.env.VITE_FLUTTERWAVE_PUBLIC_KEY } )

window.axios = axios;
app.component('insurance', Insurance);
app.component('LinkMpesaOrders', LinkMpesaPayments);
app.component('reportincidence', ReportIncidence);
app.component('bulksms-index', BulkSMS);
app.component('mpesa-accountsetup', MpesaAccountIndex);
app.component('supply-agent-assign-order', AssignOrder);
app.component('VueDatePicker', VueDatePicker);


// Price Updates
app.component('priceindex', PriceIndex);
app.component('updatebankaccount', AddUserBankAccount);

// Wallet
app.component('top-up-wallet', WalletIndex);
app.component('wallet-transfer', WalletTransfer);
app.component('make-wallet-payment', PaymentIndex);
app.component('request-payment', RequestPayment);

// fees_rent_and_taxes
app.component('create-fees', CreateFees);
app.component('create-charge', CreateCharge);
app.component('delete-charge-fee', DeleteChargeFee);
app.component('fee-detail', FeeDetail);
app.component('charge-detail', ChargeDetail);
app.component('fee-charge', FeeAndCharge);
app.component('fee-charge-cashout', FeeChargeCashout);

app.component('markets-index', MarketsIndex);
app.component('markets-members', MarketMembers);

app.component('payments-index', ReceivePayment);
app.component('pay-fees-index', PayFeesIndex);
app.component('pay-fees-show', PayFeesShow);


app.component('loan-book-summary-cards', SummaryCards);

app.component('rbac-add-admin', AddUser);

app.component('compliance-index', ComplianceIndex);
app.component('market-compliance-finance-index', MarketComplianceFinance);
app.component('finance-user-compliance', FinanceUserCompliance);

app.component('camera-capture', CameraCapture);
app.component('payfororder', PayForOrder);
app.component('upcomingpayments', UpcomingPayments);
app.component('marketupcomingpayments', MarketUpcomingPayments);
app.component('acceptorder', AcceptOrder);

app.component('memberverification', MemberVerification);
app.component('marketverification', MarketVerification);
app.component('personalinfoedit', PersonalInformationEditing);
app.component('updatepassword', UpdatePassword);
app.component('accountinformation', UpdateAccountInformation);
app.component('MarketProfile', MarketProfile);
app.component('LoginPage', LoginPage);
app.component('market-changer', MarketChanger);
app.component('upcoming-payments-page', UpcomingPayments);
app.component('create-market', MarketCreation);
app.component('create-market-member', CreateMarketMember);
app.component('finance-settings', FinanceSettings);

app.component('rbac-index', RBACIndex);
app.component('member-management', MemberManagement);

/*Profile page*/
app.component('my-profile-management', ProfilePage);
app.component('user-avatar', UserAvatar);
app.component('notifications', UserNotifications);


app.component('productsversiontwo', ProductAddition);
app.component('verificationicon', VerificationIcons);
app.component('referralcode', ReferralCode);
app.component('credit-queue', CreditQueue);
app.component('member-credit-queue', MemberCreditQueue);
app.component('onboarded-markets', Onboarding);
app.component('bank-account', BankAccount);
app.component('add-bank-account', AddBankAccount);
app.component('bank-transaction', BankTransaction);

app.component('update-bank-account-info', UpdateBankAccountInfo);

app.component('withdrawal-request', WithdrawalRequest);
app.component('create-order-by-staff', NewOrder);
app.component('user-administration', UserAdministration);
app.component('order-payouts', OrderPayouts);
app.component('member-transactions', Transaction);
app.component('subscription', Subscription);
app.component('staffmarketaccess', StaffAccess);
app.component('pos-application', PosApplication);
app.component('finance-order', FinanceOrder);

app.component('product', Product);
app.component('transactionstatuske', transactionstatus);

app.component('member-earning', Earning);
app.component('admin-notifications', AdminNotifications);


// app.component('pending-payments-component', require('./components/BulkPayments/PendingPaymentIndex.vue').default);

app.component('wholesaler-verification-documents', WholesalerVerificationDocuments)

app.component('market-compliance-index', MarketComplianceIndex)
app.component('market-compliance-show', MarketComplianceShow)

app.component('id-verification', IdVerification);
app.component('add-id-verification', AddIdVerification);

app.use(pinia)
const i18n = createI18n({
    locale: 'en',
    allowComposition: true,
})

app.use(i18n)

const user = useAuthStore();
user.updateUserDetails();

app.config.productionTip = false;

app.mount('#app')
